import React, { useEffect, useState } from 'react';
import Video from './Video';
import useScreenDrag from '../common/hooks/screenDragHook';
import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveView,
  selectSortedVideoIds,
  selectActiveVideoId,
} from '../store/screenSlice';

function Screen() {

  const [screenRef, activeView] = useScreenDrag();
  const dispatch = useDispatch();
  const isIOS = navigator.userAgent.match(/iPad|iPhone|iPod/);

  useEffect(() => {
    dispatch(setActiveView(activeView));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  const sortedVideoIds = useSelector(selectSortedVideoIds);
  const activeVideoId = useSelector(selectActiveVideoId);
  const [loadedCount, setLoadedCount] = useState(2);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight >= (scrollHeight-2)) {
      //if (scrollTop + clientHeight === scrollHeight) {
      setLoadedCount(loadedCount + 2);
    }
  };

  if (isIOS) {
    return (
      <div>
        <div className="Screen" ref={screenRef}>
          {sortedVideoIds.map((id, index) => {
            return <Video key={id} id={id} active={id === activeVideoId} />;
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="Screen" ref={screenRef} onScroll={handleScroll}>
          {sortedVideoIds.map((id, index) => {
            if (index >= loadedCount) {
              return null;
            }
            return <Video key={id} id={id} active={id === activeVideoId} />;
          })}
        </div>
      </div>
    );
  }
  
}

export default Screen;